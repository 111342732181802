angular.module('MyHippoProducer.Controllers').directive('personalInformationSection', function () {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: 'pages/policy-details/sections/personal-information/personal-information.html',
        controller: function ($scope, WorkflowAdapter) {
            $scope.hasInsuredDOB = !!WorkflowAdapter.getField('personal_information.date_of_birth');
        }
    };
});
